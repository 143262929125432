#app-searchbar .MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 12px;
    color: white;
    border-color: white;
}

#app-searchbar:hover .MuiOutlinedInput-root {
    border: none !important;
    outline: none !important;
}

.appbar-search {
    padding: 10px 16px 10px 32px;
    outline: none;
    background-color: #5365c7;
    border: none;
    border-radius: 8px;
    color: white;
    width: 100%;
    background-image: url('../images/search.png');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    font-size: 14px;
    line-height: 1.6;
}

@media screen and (max-width: 600px) {
    .appbar-search {
        display: none;
    }
}

.appbar-search::placeholder {
    color: white;
}
#app-searchbar .responsive-search-btn {
    display: none;
}

#app-searchbar .responsive-search {
    display: none;
}
@media screen and (max-width: 600px) {
    #app-searchbar .responsive-search-btn {
        flex: 1;
        padding: 6px;
        /* background-color: red; */
        display: flex;
        /* justify-content: center; */
        justify-content: flex-end;
        align-items: center;
    }
    #app-searchbar .responsive-search-btn > div {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        padding: 5px;
        cursor: pointer;
    }
    #app-searchbar .responsive-search-btn > div:hover {
        background-color: #4e60c7;
    }
    #app-searchbar .responsive-search-btn img {
        object-fit: contain;
    }
}

@media screen and (max-width: 600px) {
    #app-searchbar .responsive-search {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        height: 56px;
        width: 100%;
        z-index: 10;
        padding: 0 15px;
        background-color: #3f51b5;
    }
    #app-searchbar .responsive-search input {
        flex: 1;
        margin: 0 15px;
        padding: 10px 16px 10px 32px;
        outline: none;
        background-color: #5365c7;
        border: none;
        border-radius: 8px;
        color: white;
        width: 100%;
        background-image: url('../images/search.png');
        background-position: 10px 50%;
        background-repeat: no-repeat;
        font-size: 14px;
        line-height: 1.6;
    }
}
#app-searchbar .responsive-search input::placeholder {
    color: white;
}

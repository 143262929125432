.clickable {
    cursor: pointer;
}

.clickable:hover {
    background-color: #a3c7ef !important;
}

body {
    background-color: #f4f8f9 !important;
}

.textCenter {
    text-align: center;
}

.textRight {
    text-align: right;
}

.textLeft {
    text-align: left;
}

*:focus {
    outline: none !important;
}

a {
    text-decoration: none;
}
